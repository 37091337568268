import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import "./index.css";

import { AuthProvider } from "./helpers/auth/AuthContext";

import Root from "./routes/Root.js";
import App from "./routes/app/App.js";
import Frontpage from "./routes/frontpage/Frontpage";
import Navbar from "./components/navbar/Navbar";
import Signup from "./routes/signup/Signup";
import Login from "./routes/login/Login.js";
import Mission from "./routes/mission/Mission.js";
import Blog from "./routes/blog/Blog.js";
import Impact from "./routes/impact/Impact.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <AuthProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Root />} />
            <Route path="/home" element={<Frontpage />} />
            <Route path="/app" element={<App />} />
            <Route path="/app/sign-up" element={<Signup />} />
            <Route path="/app/login" element={<Login />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/impact" element={<Impact />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);
