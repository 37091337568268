import { useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { authContext } from "../../helpers/auth/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import Error from "../../components/error/Error";

import "./signup.css";

function Signup() {
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const [displayName, setDisplayName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const authSubmit = (e) => {
    setErrorMsg();
    e.preventDefault();
    console.log(
      "User has submitted email and password, email:",
      email,
      "Password:",
      password
    );
    setLoading(true);
    auth.createUserEmailPassword(displayName, email, password).then((res) => {
      setLoading(false);
      if (res !== true) {
        setErrorMsg(res.toString());
      }
    });
  };

  useEffect(() => {
    if (auth.user) {
      navigate("/app");
    }
  }, [auth]);

  return (
    <>
      <div
        style={{
          marginTop: "5vh",
          marginBottom: "10vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{ fontFamily: "Kanit", fontWeight: "600", fontSize: "6.5em" }}
        >
          Join Upuse
        </h1>
        <form className="signup-form" onSubmit={authSubmit}>
          <label>name:</label> <br />
          <input
            type="text"
            required
            placeholder="John Smith"
            style={{ marginBottom: "3vh" }}
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
          />{" "}
          <br />
          <label>email:</label> <br />
          <input
            type="email"
            required
            placeholder="myemail@example.com"
            style={{ marginBottom: "3vh" }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />{" "}
          <br />
          <label>password:</label> <br />
          <input
            type="password"
            required
            placeholder="p@5SworD"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />{" "}
          <br />
          {(isLoading || errorMsg) && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2vh",
              }}
            >
              {isLoading && <LoadingSpinner />}
              {errorMsg && <Error errorMessage={errorMsg} />}
            </div>
          )}
          <div style={{ display: "flex", marginTop: "3vh" }}>
            <span style={{ display: "inline-table" }}>
              <button>Sign up</button>
            </span>
            <span
              style={{
                display: "inline-table",
                paddingTop: "0",
                marginTop: "0",
                marginLeft: "3vw",
                width: "20vw",
              }}
            >
              <FontAwesomeIcon icon={faCircleInfo} /> Your credentials are
              safely secured through Firebase®, a Google® run service.
            </span>
          </div>
        </form>
      </div>
    </>
  );
}

export default Signup;
