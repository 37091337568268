import React from "react";

import "./impact.css";

const Impact = () => {
  return (
    <div className="impact">
      <h1>Impact</h1>
      <section>
        Extrapolating data from a publication by the U.S Geographical Survey in
        2006 on the efficacy of extracting minerals from cell phones, we
        estimate that with a user count of 500,000 globally, our app would be
        able to re-introduce the following amounts of minerals into the supply
        chain every year:
        <ul>
          <li>210,000 kg of copper </li>
          <li>4,600 kg of silver</li>
          <li>390 kg of gold</li>
          <li>20 kg of palladium</li>
          <li>4 kg of platinum</li>
        </ul>
      </section>
      <section>
        Comparison with terrestrial mining: Cost: From a 2015 study conducted in
        China, the cost of extracting copper and gold through urban mining is 13
        times cheaper than terrestrial mining Environmental impact:
        Terrestrially mining 1kg of gold releases 16 tons of carbon at minimum,
        whereas urban mining releases 1.3 tons (i.e. 8% of the carbon released
        by terrestrial mining) Refinement: Electronics contain up to 50 times
        the amount of precious metals for the same amount of raw mineral ore.
        Thus refining and obtaining precious metals and other minerals is more
        viable through urban mining
      </section>
      <section>
        Impact on the future of the sector: Not only will our app be useful in
        directly securing minerals, but in the long-term, it will bring more
        attention to urban mining as a viable solution. Also, an abundant supply
        of ready to be recycled electronics will prompt corporations to invest
        in developing more efficient extraction processes
      </section>
    </div>
  );
};

export default Impact;
