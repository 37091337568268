import React, { useContext, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { authContext } from "../../helpers/auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

import smartphone from "./smartphone.png";

import "./app.css";

function App() {
  const navigate = useNavigate();

  const auth = useContext(authContext);

  useEffect(() => {
    if (!auth.user) {
      navigate("/app/sign-up");
    }
  });

  return (
    <div className="app">
      <div className="blog-banner">
        <h1
          style={{
            fontFamily: "Montserrat",
            fontWeight: "900",
            fontSize: "3.5em",
            marginTop: "4vh",
          }}
        >
          Everyday is Global Recycling Day!
        </h1>
        <br />
        <NavLink
          to={"/blog/global-recycling-day"}
          style={{ marginBottom: "2vh" }}
          className={"blog-link"}
        >
          Check out our blogpost <FontAwesomeIcon icon={faCaretRight} />
        </NavLink>
        <NavLink to={"/blog "} className={"blog-link"}>
          Upuse today to get extra points{" "}
          <FontAwesomeIcon icon={faCaretRight} />
        </NavLink>
        <img
          src={smartphone}
          style={{
            position: "absolute",
            scale: "55%",
            left: "-4.5vw",
            top: "-5.25vh",
          }}
        />
      </div>
      <section className="feedback-banner">
        <Link to={"https://forms.gle/oGcRRsAWb7RrAb2h6"}>
          Answer a few questions to help us!
        </Link>
      </section>
      <section>
        <h1>An overview of your impact</h1>
      </section>
    </div>
  );
}

export default App;
