// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.aaaacom/docs/web/setup#available-libraries

import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBen-DclHbvFzi6_nkBwqfA17qsVNwsnh8",
  authDomain: "up-use.firebaseapp.com",
  projectId: "up-use",
  storageBucket: "up-use.appspot.com",
  messagingSenderId: "1071889000135",
  appId: "1:1071889000135:web:0b2e7289efa9acfb2740e2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
