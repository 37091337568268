import React from "react";

import Circular from "./circular.png";
import EWaste from "./ewaste.jpg";

import "./mission.css";

const Mission = () => {
  return (
    <div className="mission">
      <h1 className="h1">Our mission</h1>
      <main>
        <article>We want to create a circular economy</article>
        <img src={Circular} alt="circular economy diagram" className="image" />
        <article>
          Where e-waste is minimized <br /> And stops harming the environment
          and the people.
        </article>
        <img src={EWaste} alt="e-waste landfill in Africa" className="image" />
        <article style={{ marginBottom: "10vh" }}>
          UpUse will recycle your old and non-functional devices and even pay
          you do it! It's a win-win, for you and the Earth. By using our
          service, you are helping protect:
          <ul>
            <li>Ground water that provides access to water in remote areas</li>
            <li>Endangered species drinking polluted water</li>
            <li>The air we breathe</li>
            <li>The people that mining operations exploit on a daily basis</li>
          </ul>
          The way we do this is buy extracting and selling minerals from the
          devices you give us so they can be used as raw materials again. Not
          only is our profit shared with you, but to set up programs to better
          educate people and ensure less e-waste is produced in the first place.
        </article>
      </main>
    </div>
  );
};

export default Mission;
