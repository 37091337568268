import { createContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

import { auth } from "../../firebase";

export const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState();

  const loginEmailPassword = async (loginEmail, loginPassword) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );

      return true;
    } catch (err) {
      console.log("error logging in user, Firebase response", err);
      return err;
    }
  };
  const createUserEmailPassword = async (name, signupEmail, signupPassword) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        signupEmail,
        signupPassword
      );
      updateProfile(userCredential.user, {
        displayName: name,
        photoURL: `/images/pfps/${Math.floor(Math.random() * 3 + 1)}.webp`,
      });

      return true;
    } catch (err) {
      console.error("error creating user, Firebase response", err);
      return err;
    }
  };

  const updateUserPfp = (photoLink) => {
    updateProfile(user, { photoURL: photoLink });
  };

  const logoutUser = () => {
    signOut(auth).then(() => {
      setUser(null);
    });
  };

  useEffect(() => {
    const authChange = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser !== null) {
        setUser(currentUser);
      }
    });
    return () => authChange();
  }, []);

  return (
    <authContext.Provider
      value={{
        user,
        loginEmailPassword,
        createUserEmailPassword,
        logoutUser,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
