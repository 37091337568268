import { useContext, useEffect, useState } from "react";
import { authContext } from "../../helpers/auth/AuthContext";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import "./navbar.css";

const Navbar = () => {
  const { user, logoutUser } = useContext(authContext);

  return (
    <>
      <nav>
        <ul>
          <span className="left">
            <li>
              {user ? (
                <NavLink to={"/app"}>Dashboard</NavLink>
              ) : (
                <NavLink to={""}>{user ? "Dashboard" : "Home"}</NavLink>
              )}
            </li>
            <li>
              <NavLink to={"/mission"}>Our mission</NavLink>
            </li>
            <li>
              <NavLink to={"/impact"}>Impact</NavLink>
            </li>
            <li>
              <NavLink to={"/blog"}>Blog</NavLink>
            </li>
          </span>
          <span className="right">
            <li>
              {user ? (
                <>
                  <NavLink
                    to={"/app/device"}
                    className={"auth-button"}
                    style={{ marginRight: "2vw" }}
                  >
                    Upuse my device
                  </NavLink>
                  <ProfilePic pfp={user.photoUrl} logoutfn={logoutUser} />{" "}
                </>
              ) : (
                <SignUp />
              )}
            </li>
          </span>
        </ul>
      </nav>
      <hr
        style={{ color: "#4cbb17", backgroundColor: "#4cbb17", height: "2px" }}
      />
    </>
  );
};

const SignUp = () => {
  return (
    <>
      <NavLink to={"/app/sign-up"} className="auth-button">
        Join now
      </NavLink>
      <span style={{ margin: "0 0.75vw", color: "grey" }}>or</span>
      <NavLink to={"/app/login"} className="auth-button">
        Login
      </NavLink>
    </>
  );
};

const ProfilePic = ({ pfp, logoutfn }) => {
  const [open, setOpen] = useState(false);

  const Logout = () => {
    return (
      <div
        className="logout-button"
        onClick={() => {
          logoutfn();
        }}
      >
        Logout
      </div>
    );
  };

  return (
    <>
      <span onClick={() => setOpen(!open)}>
        <img
          src={pfp ? pfp : "/images/pfps/1.webp"}
          style={{
            position: "absolute",
            top: "2vh",
            width: "7.75vh",
            height: "7.75vh",
            borderRadius: "3em",
          }}
          alt="Profile Picture"
        />
        <FontAwesomeIcon icon={faCaretDown} className="pfp-caret-down" />
        <span style={{ position: "relative" }}>
          {open && (
            <div className="dropdown">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "2vh",
                  paddingBottom: "2vh",
                }}
              >
                <Logout />
              </div>
            </div>
          )}
        </span>
      </span>
    </>
  );
};

export default Navbar;
