import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { authContext } from "../../helpers/auth/AuthContext";

import "./login.css";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import Error from "../../components/error/Error";

function Login() {
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const authSubmit = (e) => {
    e.preventDefault();
    console.log(
      "User has submitted email and password, email:",
      email,
      "Password:",
      password
    );
    setLoading(true);
    auth.loginEmailPassword(email, password).then((res) => {
      setLoading(false);
      if (res !== true) {
        setErrorMsg(res.toString());
      }
    });
  };

  useEffect(() => {
    if (auth.user) {
      navigate("/app");
    }
  }, [auth]);

  return (
    <>
      <div
        style={{
          marginTop: "5vh",
          marginBottom: "10vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{ fontFamily: "Kanit", fontWeight: "600", fontSize: "6.5em" }}
        >
          Welcome back!
        </h1>
        <form className="login-form" onSubmit={authSubmit}>
          <label>email:</label> <br />
          <input
            type="email"
            required
            placeholder="myemail@example.com"
            style={{ marginBottom: "3vh" }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />{" "}
          <br />
          <label>password:</label> <br />
          <input
            type="password"
            required
            placeholder="p@5SworD"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />{" "}
          <br />
          {(isLoading || errorMsg) && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2vh",
                marginBottom: "1vh",
              }}
            >
              {isLoading && <LoadingSpinner />}
              {errorMsg && <Error errorMessage={errorMsg} />}
            </div>
          )}
          <button>Login</button>
        </form>
      </div>
    </>
  );
}

export default Login;
