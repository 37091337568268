import Slideshow from "./slideshow";

import styles from "./frontpage.module.css";

function Frontpage() {
  return (
    <>
      <Slideshow />
      <main className={styles.main}>
        <h1 className={styles.h1}>Join UpUse</h1>
        <article
          style={{
            marginTop: "5vh",
            fontFamily: "Montserrat",
            marginLeft: "15vw",
            marginRight: "15vw",
            fontSize: "1.5em",
            marginBottom: "10vh",
          }}
        >
          We're building an innovative platform, UpUse, designed to
          revolutionize how we approach electronic device disposal and
          sustainable technology adoption. Our goal is to incentivize a circular
          economy by encouraging users to recycle their outdated devices.
          Through a combination of monetary rewards, global initiatives, and
          engaging collection competitions, we aim to attract a larger community
          to participate actively. UpUse integrates seamlessly with established
          mineral extraction processes used by major tech companies and
          industrial recycling facilities. By doing so, we contribute to
          securing crucial minerals necessary for a greener, fossil-free future.
          Our platform stands as a proactive solution, addressing the
          environmental impact and ethical concerns associated with traditional
          mining practices. By reducing the need for new mineral extraction, we
          mitigate environmental degradation and prevent human exploitation
          often linked with mining activities. UpUse represents a transformative
          step towards a more sustainable and ethical approach to technology
          consumption, safeguarding our planet and fostering a global commitment
          to responsible resource management.
        </article>
      </main>
    </>
  );
}

export default Frontpage;
