import { useNavigate } from "react-router-dom";

import { useContext, useEffect } from "react";
import { authContext } from "../helpers/auth/AuthContext";
import Frontpage from "./frontpage/Frontpage";

const App = () => {
  const navigate = useNavigate();
  const auth = useContext(authContext);

  useEffect(() => {
    if (auth.user) {
      navigate("/app");
    }
  });

  return <Frontpage />;
};
export default App;
